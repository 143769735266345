let validator = $('#auth-form').validate({
    rules: {
        email: {
            required: true,
            email: true
        },
        password: "required",
    },
    highlight: function (element) {
        $(element).closest('.input-line').addClass('has-error');
    },
    unhighlight: function (element) {
        $(element).closest('.input-line').removeClass('has-error');
    },
    messages: {
        email: {
            required: "Поле обязательно для заполнения",
            email: "Некорректный email",
        },
        password: "Поле обязательно для заполнения",
    },
});

$('#auth-form').on('submit', function(e) {

    if (validator.valid()) {
        e.preventDefault();
        $(this).request('Login::onAjax', {
            loading: $.oc.stripeLoadIndicator,
            success: function(obResponse) {
                if (!obResponse) {
                    return;
                }

                if (!!obResponse['X_OCTOBER_REDIRECT']) {
                    return this.success(obResponse);
                }

                if (!obResponse.status) {
                    $.oc.flashMsg({text: obResponse.message, 'class': 'flash-message error fade in', 'interval': 10});

                    return;
                }

                return this.success(obResponse);
            }
        });
        return false;
    }

});
