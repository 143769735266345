$('.btn-add-to-cart').on('click', function (e) {
    const offer_id = $(e.currentTarget).data('offer'),
        btnThis = $(e.currentTarget),
        btnInCartTrue = $(e.currentTarget).parent().find('.btn__incart-true');

    const cartData = [
        {
            'offer_id': offer_id,
            'quantity': 1,
        }
    ];

    $.request('Cart::onAdd', {
        data: { 'cart': cartData },
        success: function (response) {
            if (!!response && response.status) {
                btnThis.addClass('hidden');
                btnInCartTrue.removeClass('hidden');
                $.oc.flashMsg({ text: 'Товар успешно добавлен в корзину!', class: 'flash-message-success' });

                updateCartLinkHeader();
            } else {
                $.oc.flashMsg({ text: 'Произошла ошибка при добавлении товара в корзину.', class: 'flash-message-error' });
            }
        }
    });

    var cart = $('._ajax_cart_link_header_wrapper');
    var imgtodrag = $(this).closest('.product-card').find('img').eq(0);
    if (imgtodrag) {
        var imgclone = imgtodrag.clone()
            .offset({
                top: imgtodrag.offset().top,
                left: imgtodrag.offset().left
            })
            .css({
                'opacity': '0.5',
                'position': 'absolute',
                'height': '250px',
                'width': '250px',
                'z-index': '100'
            })
            .appendTo($('body'))
            .animate({
                'top': cart.offset().top + 10,
                'left': cart.offset().left + 10,
                'width': 75,
                'height': 75
            }, 1000);

        imgclone.animate({
            'width': 0,
            'height': 0
        }, function () {
            $(this).detach()
        });
    }
});
