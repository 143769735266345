let $reg_form = $('#registration-form');

let validator = $reg_form.validate({
    rules: {
        email: {
            required: true,
            email: true
        },
        password: "required",
        password_confirmation: {
            equalTo: "#password"
        },
        last_name: "required",
        name: "required",
        middle_name: "required",
        phone: "required",
        "property[country]": "required",
        "property[postcode]": "required",
        "property[state]": "required",
        "property[city]": "required",
        "property[address]": "required",
    },
    highlight: function (element) {
        $(element).closest('.input-line').addClass('has-error');
    },
    unhighlight: function (element) {
        $(element).closest('.input-line').removeClass('has-error');
    },
    messages: {
        email: {
            required: "Поле обязательно для заполнения",
            email: "Некорректный email",
        },
        password: "Поле обязательно для заполнения",
        password_confirmation: {
            required: "Поле обязательно для заполнения",
            equalTo: "Пароли не совпадают",
        },
        last_name: "Поле обязательно для заполнения",
        name: "Поле обязательно для заполнения",
        middle_name: "Поле обязательно для заполнения",
        phone: "Поле обязательно для заполнения",
        "property[country]": "Поле обязательно для заполнения",
        "property[postcode]": "Поле обязательно для заполнения",
        "property[state]": "Поле обязательно для заполнения",
        "property[city]": "Поле обязательно для заполнения",
        "property[address]": "Поле обязательно для заполнения",
    },
});

let $email = $('#registration-form input[name=email]');
let typingTimer;

$email.on('keyup', function () {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(onCheckEmail, 1000);
});

$email.on('keydown', function () {
    clearTimeout(typingTimer);
    $('#name-error').remove();
    $email.parent('.input-line').removeClass('has-error');
    $email.parent('.input-line').removeClass('no-error');
});

function onCheckEmail() {
    if (validator.element($email)) {
        $.request('Registration::onCheckEmail', {
            data: {'email': $('input[name="email"]').val()},
            success: function(data) {
                if (data.status) {
                    $email.parent('.input-line').addClass('no-error');
                } else {
                    $email.parent('.input-line').addClass('has-error');
                    $email.after('<label id="name-error" class="error" for="name">email занят, выберите другой</label>');
                }
            }
        });
    }
}

$reg_form.on('submit', function(e) {

    if (validator.valid()) {
        e.preventDefault();
        $(this).request('Registration::onAjax', {
            loading: $.oc.stripeLoadIndicator,
            success: function(obResponse) {
                if (!obResponse) {
                    return;
                }

                if (!!obResponse['X_OCTOBER_REDIRECT']) {
                    return this.success(obResponse);
                }

                if (!obResponse.status) {
                    $.oc.flashMsg({text: obResponse.message, 'class': 'flash-message error fade in', 'interval': 10});

                    return;
                }

                return this.success(obResponse);
            }
        });
        return false;
    }

});
