global.jquery = global.jQuery = global.$ = require('jquery');

window.Popper = require('popper.js');

//import './assets/vendor/bootstrap/bootstrap.js';
import 'bootstrap';
import 'jquery-validation';
import IMask from 'imask';

import './pages/catalog/category.js';
import './pages/catalog/product.js';
import './pages/shop/cart.js';
import './pages/shop/checkout.js';
import './pages/user/registration';
import './pages/user/login';
import './pages/user/password-restore';
import './pages/user/password-reset';
import './pages/user/profile';

$('.carousel').carousel({
    interval: 0
});

$('.navbar-toggler').click(function () {
    $(this).toggleClass('active');
});

$('#catalogNaxToggler').click(function () {
    $('.catalog__nav').slideToggle();
});

$(function () {

    /*MENU*/

    $('.menu-main a').hover(function () {
        $('.menu-main a').removeClass('active');
        $(this).addClass('active');
        var attr = $(this).attr("data-link");
        $('.menu-right-item').removeClass('active');
        $(attr).addClass('active');

    });

    $('.mob').click(function () {
        if ($(window).width() < 768) {
            $('.drop-menu').removeClass('open');
            $(this).find('.drop-menu').addClass('open');
        }
    });

});

var input = $('.phonetext')[0];
let phoneMask = IMask(input, {

    mask: '{+7} (#00) 000 0000',

    definitions: {
        '#': /[012345679]/
    },

    lazy: false,

    placeholderChar: ' '

});

$(document).ready(function () {

    $('[data-toggle="tooltip"]').tooltip();

    $("._ajax_cart_link_header_wrapper").sticky({});
});


/**
 * Yandex map lazy loading
 */
document.addEventListener('DOMContentLoaded', function() {
    /** init gtm after 10 seconds - this could be adjusted */
    setTimeout(initYandexMap, 10000);
});
document.addEventListener('scroll', initYandexMapOnEvent);
document.addEventListener('mousemove', initYandexMapOnEvent);
document.addEventListener('touchstart', initYandexMapOnEvent);

function initYandexMapOnEvent (e) {
    initYandexMap();
    // remove the event listener that got triggered
    e.currentTarget.removeEventListener(e.type, initYandexMapOnEvent);
}

function initYandexMap () {
    if (window.yandexMapDidInit) {
        return false;
    }
    window.yandexMapDidInit = true; // flag to ensure script does not get added to DOM more than once.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A5f15a3d762c202ba94d9d91393cd956bbb8798f5e10264eb3b8971442a6a1fa7&amp;width=100%25&amp;height=600&amp;lang=ru_RU&amp;scroll=true';

    document.getElementById("YandexMap").appendChild(script);
}


/**
 * Yandex metrika lazy loading
 */

// Для бота Яндекса грузим Метрику сразу без "отложки",
// чтобы в панели Метрики были зелёные кружочки
// при проверке корректности установки счётчика.
if (navigator.userAgent.indexOf('YandexMetrika') > -1) {
    initYandexMetrika();
} else {
    document.addEventListener('DOMContentLoaded', function() {
        /** init gtm after 5 seconds - this could be adjusted */
        setTimeout(initYandexMetrika, 5000);
    });
    document.addEventListener('scroll', initYandexMetrikaOnEvent);
    document.addEventListener('mousemove', initYandexMetrikaOnEvent);
    document.addEventListener('touchstart', initYandexMetrikaOnEvent);
}

function initYandexMetrikaOnEvent (e) {
    initYandexMetrika();
    // remove the event listener that got triggered
    e.currentTarget.removeEventListener(e.type, initYandexMetrikaOnEvent);
}

function initYandexMetrika () {
    if (window.yandexMetrikaDidInit) {
        return false;
    }
    window.yandexMetrikaDidInit = true; // flag to ensure script does not get added to DOM more than once.
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(54544330, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
    });
}


/**
 * Jivosite lazy loading
 */
// document.addEventListener('DOMContentLoaded', function() {
//     /** init gtm after 10 seconds - this could be adjusted */
//     setTimeout(initJivoSite, 8000);
// });
// document.addEventListener('scroll', initJivoSiteOnEvent);
// document.addEventListener('mousemove', initJivoSiteOnEvent);
// document.addEventListener('touchstart', initJivoSiteOnEvent);

// function initJivoSiteOnEvent (e) {
//     initJivoSite();
//     // remove the event listener that got triggered
//     e.currentTarget.removeEventListener(e.type, initJivoSiteOnEvent);
// }

// function initJivoSite () {
//     if (window.jivoSiteDidInit) {
//         return false;
//     }
//     window.jivoSiteDidInit = true; // flag to ensure script does not get added to DOM more than once.
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.async = true;
//     script.src = '//code-ya.jivosite.com/widget/x4aTQUI2Ld';
//
//     document.getElementById("YandexMap").appendChild(script);
// }



/**
 * WhatsApp widget lazy loading
 */
document.addEventListener('DOMContentLoaded', function() {
    /** init gtm after 10 seconds - this could be adjusted */
    setTimeout(initWhatsApp, 8000);
});
document.addEventListener('scroll', initWhatsAppOnEvent);
document.addEventListener('mousemove', initWhatsAppOnEvent);
document.addEventListener('touchstart', initWhatsAppOnEvent);

function initWhatsAppOnEvent (e) {
    initWhatsApp();
    // remove the event listener that got triggered
    e.currentTarget.removeEventListener(e.type, initWhatsAppOnEvent);
}

function initWhatsApp () {
    if (window.whatsAppDidInit) {
        return false;
    }
    window.whatsAppDidInit = true; // flag to ensure script does not get added to DOM more than once.
    !function(){var e={"token":"79234996444","position":"right","bottomSpacing":"40","callToActionMessage":"Напишите нам в WhatsApp!","displayOn":"everywhere","subtitle":"Интернет магазин","message":{"name":"Привет из Сибири","content":"Здравствуйте! Чем могу Вам помочь?"}},t=document.location.protocol+"//bothelp.io",o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=t+"/widget-folder/widget-whatsapp-chat.js",o.onload=function(){BhWidgetWhatsappChat.init(e)};var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(o,n)}();
}
