let $checkout_form = $('#checkout-form');

let validator = $checkout_form.validate({
    rules: {
        email: {
            email: true
        },
        last_name: "required",
        name: "required",
        middle_name: "required",
        phone: "required",
        "shipping_postcode": "required",
        "property[state]": "required",
        "property[city]": "required",
        "property[address]": "required",
    },
    highlight: function (element) {
        $(element).closest('.input-line').addClass('has-error');
    },
    unhighlight: function (element) {
        $(element).closest('.input-line').removeClass('has-error');
    },
    messages: {
        email: {
            email: "Некорректный email",
        },
        last_name: "Поле обязательно для заполнения",
        name: "Поле обязательно для заполнения",
        middle_name: "Поле обязательно для заполнения",
        phone: "Поле обязательно для заполнения",
        "shipping_postcode": "Поле обязательно для заполнения",
        "shipping_state": "Поле обязательно для заполнения",
        "shipping_city": "Поле обязательно для заполнения",
        "shipping_address": "Поле обязательно для заполнения",
    },
});

$checkout_form.on('submit', function (e) {

    if (validator.valid()) {
        e.preventDefault();

        let data = {
            'order': {
                'shipping_type_id': 1,
                'payment_method_id': 1,
                'property': {
                    'shipping_state': $('#checkout-form input[name=shipping_state]').val(),
                    'shipping_city': $('#checkout-form input[name=shipping_city]').val(),
                    'shipping_address1': $('#checkout-form input[name=shipping_address]').val(),
                    'shipping_postcode': $('#checkout-form input[name=shipping_postcode]').val(),
                }
            },
            'user': {
                'last_name': $('#checkout-form input[name=last_name]').val(),
                'name': $('#checkout-form input[name=name]').val(),
                'middle_name': $('#checkout-form input[name=middle_name]').val(),
                'phone': $('#checkout-form input[name=phone]').val(),
                'email': $('#checkout-form input[name=email]').val()
            }
        };

        $(this).request('MakeOrder::onCreate', {
            loading: $.oc.stripeLoadIndicator,
            'data': data,
            success: function (obResponse) {

                if (!obResponse) {
                    return;
                }

                if (!!obResponse['X_OCTOBER_REDIRECT']) {
                    return this.success(obResponse);
                }

                if (!obResponse.status) {
                    //Show message with error
                    return;
                }

                //Show "Success message"
                return this.success(obResponse);
            }
        });
    }

});
