$('.cart__delete-btn').on('click', function (e) {
    const btnRemoveItem = $(e.currentTarget),
        cart = $('.basket-block'),
        cartItemsNumber = cart.children().length;

    if (cartItemsNumber > 2) {
        btnRemoveItem.parent().parent('.basket-item').remove();
    } else if (cartItemsNumber === 2) {
        cart.remove();
        $('.basket-total').remove();
        $('.cart__empty').removeClass('hidden');
    }

    const itemId = btnRemoveItem.attr('data-id'),
        data = { 'cart': [itemId] };

    $.request('Cart::onRemove', {
        'data': data,
        success: function (response) {
            if (!!response && response.status && !!response.data) {
                $('#cart__total-value').html(response.data.total_price.price);
                updateCartLinkHeader();
            }
        }
    });
});

$('.basket-num-minus').on('click', function (e) {
    const btnMinus = $(e.currentTarget),
        numInput = btnMinus.siblings('.basket-num-input');

    let num = +numInput.val() - 1;
    numInput.val(num);
    numInput.trigger('change')
});

$('.basket-num-plus').on('click', function (e) {
    const btnMinus = $(e.currentTarget),
        numInput = btnMinus.siblings('.basket-num-input');

    let num = +numInput.val() + 1;
    numInput.val(num);
    numInput.trigger('change')
});

$('.quantity').on('change', function (e) {
    const quantity = $(e.currentTarget);
    let num = +quantity.val(),
        max = parseInt(quantity.attr('max'));
    if (num <= 1) {
        num = 1;
    }
    if (num >= max) {
        num = max;
    }
    quantity.val(num);


    const $quantityItem = $(e.currentTarget),
        cartItem = $quantityItem.parents('.basket-item'),
        dataPositionId = cartItem.attr('data-position-id'),
        data = {
            'cart': [
                {
                    'offer_id': cartItem.attr('data-offer-id'),
                    'quantity': $quantityItem.val()
                }
            ],
            'shipping_type_id': 1
        };

    $.request('Cart::onUpdate', {
        data: data,
        success: function (response) {
            if (!!response && response.status && !!response.data) {
                const cartData = response.data,
                    priceData = cartData.position[dataPositionId];

                cartItem.find('.cart__position-price-value').html(priceData.price);
                $('#cart__total-value').html(cartData.position_total_price.price);

                updateCartLinkHeader();
            }
        }
    });
});