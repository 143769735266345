import ShopaholicProductList from "@lovata/shopaholic-product-list/shopaholic-product-list";
import ShopaholicPagination from "@lovata/shopaholic-product-list/shopaholic-pagination";
import ShopaholicSorting from "@lovata/shopaholic-product-list/shopaholic-sorting";

const obListHelper = new ShopaholicProductList();
obListHelper.setAjaxRequestCallback((obRequestData) => {
    obRequestData.update = { 'catalog/catalog-content-ajax': `.products__wrapper` };
    obRequestData.loading = $.oc.stripeLoadIndicator.show();
    obRequestData.complete = () => {
        $('body, html').animate({ scrollTop: 280 }, 350);
        $.oc.stripeLoadIndicator.hide();
    };

    return obRequestData;
});

// pagination
const obPaginationHelper = new ShopaholicPagination(obListHelper);
obPaginationHelper.init();

// sorting
const obSortingHelper = new ShopaholicSorting(obListHelper);
obSortingHelper.init();