let $form = $('#profile-form');

let validator = $form.validate({
    rules: {
        last_name: "required",
        name: "required",
        middle_name: "required",
        phone: "required",
        "property[country]": "required",
        "property[postcode]": "required",
        "property[state]": "required",
        "property[city]": "required",
        "property[address]": "required",
    },
    highlight: function (element) {
        $(element).closest('.input-line').addClass('has-error');
    },
    unhighlight: function (element) {
        $(element).closest('.input-line').removeClass('has-error');
    },
    messages: {
        last_name: "Поле обязательно для заполнения",
        name: "Поле обязательно для заполнения",
        middle_name: "Поле обязательно для заполнения",
        phone: "Поле обязательно для заполнения",
        "property[country]": "Поле обязательно для заполнения",
        "property[postcode]": "Поле обязательно для заполнения",
        "property[state]": "Поле обязательно для заполнения",
        "property[city]": "Поле обязательно для заполнения",
        "property[address]": "Поле обязательно для заполнения",
    },
});

$form.on('submit', function(e) {

    if (validator.valid()) {
        e.preventDefault();
        $(this).request('UserPage::onAjax', {
            loading: $.oc.stripeLoadIndicator,
            success: function(obResponse) {
                if (!obResponse) {
                    return;
                }

                if (!!obResponse['X_OCTOBER_REDIRECT']) {
                    return this.success(obResponse);
                }

                if (!obResponse.status) {
                    $.oc.flashMsg({text: obResponse.message, 'class': 'flash-message error fade in', 'interval': 10});

                    return;
                }

                return this.success(obResponse);
            }
        });
        return false;
    }

});
