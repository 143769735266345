let $pwreset = $('#pwdreset-form');

let validator = $pwreset.validate({
    rules: {
        password: "required",
        password_confirmation: {
            equalTo: "#password"
        },
    },
    highlight: function (element) {
        $(element).closest('.input-line').addClass('has-error');
    },
    unhighlight: function (element) {
        $(element).closest('.input-line').removeClass('has-error');
    },
    messages: {
        password: "Поле обязательно для заполнения",
        password_confirmation: {
            required: "Поле обязательно для заполнения",
            equalTo: "Пароли не совпадают",
        },
    },
});

$pwreset.on('submit', function(e) {

    if (validator.valid()) {
        e.preventDefault();
        $(this).request('ResetPassword::onAjax', {
            loading: $.oc.stripeLoadIndicator,
            success: function(obResponse) {
                if (!obResponse) {
                    return;
                }

                if (!!obResponse['X_OCTOBER_REDIRECT']) {
                    return this.success(obResponse);
                }

                if (!obResponse.status) {
                    $.oc.flashMsg({text: obResponse.message, 'class': 'flash-message error fade in', 'interval': 10});

                    return;
                }

                return this.success(obResponse);
            }
        });
        return false;
    }

});
